<template>
  <div class="cardSurvey">
    <div class="cardSurvey-content">
      <div class="btnClose" @click="sendEvent()">
        <CIcon name="cil-x" height="25" />
      </div>

      <div class="cardSurvey-title">{{ this.isNew ? this.$t("create_survey") : this.isDelete?  this.$t("delete_survey"):  this.$t("edit_survey") }}</div>
      <hr />

      <div v-if="notification">
        <CAlert :color="notificationType">
          {{ notification }}
        </CAlert>
      </div>


        <BlockInfo
          :title="$t('title')"
          v-model="_survey.title"
		  :disabled="this.isDelete"
        />
        <BlockInfo
        :title="$t('question') + 'nº1' "
          v-model="_survey.question_1"
		   :disabled="this.isDelete"
        />
        <BlockInfo
        :title="$t('question') + 'nº2' "
          v-model="_survey.question_2"
		   :disabled="this.isDelete"
        />
        <BlockInfo
          :title="$t('question') + 'nº3' "
          v-model="_survey.question_3"
		   :disabled="this.isDelete"
        />
		<BlockInfoDatePicker
        :title="$t('start') + ' ' + $t('date')"
          v-model="_survey.start_date"
		   :disabled="this.isDelete"
        />
		<BlockInfoDatePicker
          :title="$t('end') + ' ' + $t('date')"
          v-model="_survey.end_date"
		   :disabled="this.isDelete"
        />
        
        <BlockInfo
          :title="$t('interval') + '(' + $t('days') +  ')'"
		   v-model="_survey.interval_days"
		    :disabled="this.isDelete"
		  :type="'number'"
        />
		<BlockInfoSelect
          :title="$t('country')"
          v-model="_survey.country"
          :selected="_survey.country || countryCodes[0]"
          :values="countryCodes"
		   :disabled="this.isDelete"
        />
        

        <div class="cardSurvey-sectionButtons">
          <CButton
            color="white"
            size="lg"
            class="cardSurvey-button"
            @click="sendEvent()"
            >{{$t('cancel')}}</CButton
          >
          <CButton
            :color="this.isDelete ? 'danger' : 'success'"
            size="lg"
            class="cardSurvey-button"
            @click="actionEvent()"
            >{{ this.isNew ?  this.$t('create') : this.isDelete? this.$t('delete'):  this.$t('save') }}</CButton
          >
        </div>
    </div>
    <div class="cardSurveyShadow" @click="sendEvent()"></div>
  </div>
</template>

<script>
import BlockInfo from "./BlockInfo";
import BlockInfoDatePicker from "./BlockInfoDatePicker";
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
const moment = require("moment");

export default {
  name: "cardSurvey",
  components: {
    BlockInfo,
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
    DatePicker,
    BlockInfoDatePicker
  },
  props: {
    survey: Object,
    countryCodes: Array,
    isNew: Boolean,
    isDelete: Boolean,
    notification: String,
    notificationType: String,
  },

  computed: {

    _survey() {
      return this.survey;
    },
  },
  data() {
    return {

    };
  },
  created() {
    const self = this;
  },
  methods: {
    sendEvent() {
      (this.classHidden = "hidden"), this.$emit("close-card", false);
    },



    actionEvent() {
      const self = this;
      if (self.isDelete) {
        self.$emit("delete-survey", self._survey);
      } else if (self.formValidate()) {
        if (self.isNew) {
          self.$emit("new-survey", self._survey);
        } else {
          self.$emit("edit-survey", self._survey);
        }
      }
    },

    formValidate() {
      const self = this;
      if (!self._survey.title) {
        self.$emit("card-survey-error", "Title is required");
        return false;
      }
      if (!self._survey.question_1 || !self._survey.question_2 || !self._survey.question_3) {
        self.$emit("card-survey-error", "Please, complete all 3 questions");
        return false;
      }

      if (!self._survey.start_date) {
        self.$emit("card-survey-error", "Start date is required");
        return false;
      }
      if (!self._survey.end_date) {
        self.$emit("card-survey-error", "End date is required");
        return false;
      }
	  if (!self._survey.country) {
        self.$emit("card-survey-error", "Country is required");
        return false;
      }
      if (!self._survey.interval_days) {
        self.$emit("card-survey-error", "Interval is required and must be a number");
        return false;
      }
	  if( moment(self._survey.start_date).isSameOrAfter(moment(self._survey.end_date))){
        self.$emit("card-survey-error", "End date should be after start date");
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.cardSurveyShadow {
  width: 100%;
  height: 100vh;
  background: #000015;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10100;
}
.cardSurvey {
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}
.cardSurvey-content {
  position: absolute;
  right: 0;
  top: 0;
  width: 90%;
  height: 100vh;
  max-width: 700px;
  background: #fff;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 10200;
  padding-top: 53.78px;
  padding-left: 3em;
  padding-right: 3em;

  overflow-y: auto;
}
.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5em;
  cursor: pointer;
}

.cardSurvey-title {
  font-size: 20px;
  margin-bottom: 1em;
}
.cardSurvey-subtitle {
  width: 100%;
  font-size: 16px;
  /* margin-top: 1em;
  margin-bottom: 1em; */
  padding: 1em;
  line-height: 1.3;
}
.btn-plus {
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.btn-plus:hover {
  background: rgba(0, 0, 0, 0.05);
}

.cardSurvey-inputGroup {
  width: 25%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.cardSurvey-sectionButtons {
  padding: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardSurvey-button {
  margin-left: 1em;
  font-size: 14px;
}
</style>